<template>
    <v-app id="app" :style="$route.name === 'LiveScore' ? 'height: 130px; width: 600px;' : ''">
        <div v-if="$route.name === 'LiveScore'">
            <router-view />
        </div>
        <v-container v-else class="bg mx-0 px-0 mb-15" :class="$vuetify.breakpoint.mobile ? 'mb-15 mt-0' : 'my-0 py-0'" fluid :style="`{background: url(${getBackground}) !important; }`">
            <section-title v-if="$vuetify.breakpoint.mobile" :title="typeof $route.meta === 'string' ? $route.meta : '' " :class="$vuetify.breakpoint.mobile && $route.name !== 'Team' && $route.name !== 'Match' && $route.name !== 'FacebookPostMatch' ? 'mb-16' : ''" />
            <nav-bar v-if="$route.name !== 'FacebookPostMatch'" :class="$vuetify.breakpoint.mobile || $route.name === 'Team' || $route.name === 'Match' || $route.name === 'FacebookPostMatch' ? '' : 'mb-16 py-10'" />
            <v-row no-gutters :class="$vuetify.breakpoint.mobile || $route.name === 'Team' || $route.name === 'Match' || $route.name === 'FacebookPostMatch' ? '' : 'px-0'" class="py-0 my-0">
                <!-- <aegean-ball v-if="!($route.name === 'Team' || $route.name === 'Match')"/> -->
                <v-col cols="12" class="py-0 my-0" :class="$vuetify.breakpoint.mobile || $route.name === 'Team' || $route.name === 'Match' || $route.name === 'FacebookPostMatch' ? '' : 'px-16'">
                    <router-view :class="$vuetify.breakpoint.mobile || $route.name === 'Team' || $route.name === 'Match' || $route.name === 'FacebookPostMatch' ? '' : 'px-16'" />
                </v-col>
            </v-row>
        </v-container>
        <custom-footer v-if="!$vuetify.breakpoint.mobile && $route.name !== 'LiveScore'" style="position: fixed; bottom: 0;" />
        <v-btn
            v-show="fab"
            v-scroll="onScroll"
            fab
            dark
            fixed
            :bottom="!$vuetify.breakpoint.mobile"
            :style="$vuetify.breakpoint.mobile ? 'bottom: 80px; right: 5px;' : 'bottom: 100px; right: 25px;'"
            :right="!$vuetify.breakpoint.mobile"
            :small="$vuetify.breakpoint.mobile"
            color="#dddddf"
            class="custom-orange"
            style="z-index: 14;"
            @click="toTop"
        >
            <v-icon :large="!$vuetify.breakpoint.mobile" color="white">mdi-chevron-up</v-icon>
        </v-btn>
    </v-app>
</template>

<script>
    import NavBar from '@/components/NavBar.vue'
    import CustomFooter from '@/components/Footer.vue'
    // import AegeanBall from './components/AegeanBall.vue'
    import SectionTitle from '@/components/Title'
    export default {
        name: 'App',
        components: {
            NavBar,
            SectionTitle,
            CustomFooter
        },
        data() {
            return {
                backgrounds: ['@/assets/basketball-bg-1.jpg', '@/assets/dunk-bg-1.jpg', '@/assets/dunk-bg-2.jpg'],
                fab: false
            }
        },
        watch: {
            $route() {
                document.title = `BIG | ${this.$route.meta}`
            }
        },
        created() {
        },
        methods: {
            getBackground() {
                return require(this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)])
            },
            onScroll(e) {
                if (typeof window === 'undefined') return
                const top = window.pageYOffset || e.target.scrollTop || 0
                this.fab = top > 20
            },
            toTop() {
                this.$vuetify.goTo(0)
            }
        }
    }
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Mansalva&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

.v-slide-group__prev {
    display: none !important;
}

.v-tab--active {
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 70%, orange 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.custom-orange {
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 50%, orange 100%);
}

.custom-orange-text {
    background-image: linear-gradient(90deg, #ef4444 0%, #ff7133 70%, orange 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

#app {
    font-family: Mansalva, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.bg {
    /* position: absolute; */
    background: url('assets/dunk-bg-2.jpg'), #dddddf;
    background-size: cover;
    background-repeat: no-repeat;

    /* background: rgb(255,162,0);
    background: linear-gradient(90deg, rgba(255,162,0,1) 0%, rgba(240,240,242,1) 50%); */
    width: 100%;
    height: auto;
    min-height: 100%;
}

@media only screen and (max-width: 1000px) {
    .bg {
        background: url('assets/dunk-bg-2.jpg') #dddddf top 65px right;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.v-data-table {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 400;
}

thead {
    white-space: nowrap;
}

.secondary-font {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 700;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
}

.clickable {
    cursor: pointer !important;
}

table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    left: 0;
    background: white;
}
</style>
